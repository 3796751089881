import { Trans } from "@lingui/macro";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import styled from "styled-components";
import React, { useState } from "react";
import Container from "../../components/Container";
import AnimatedDiv from "../../components/AnimatedDiv";
import Seo from "../../components/seo";
import HeroLading from "../../components/HeroLanding";
import ProductModal from "../../components/ProductModal";
import SecondaryButton from "../../components/SecondaryButton";
import CardColor from "../../components/CardColor";
import blueIcon from "../../images/icons/blue.png";
import greenIcon from "../../images/icons/green.png";
import OrangeIcon from "../../images/icons/orange.png";
import yellowIcon from "../../images/icons/yellow.png";
import dueDiligenceEnhanceImg from "../../images/invesment-solution/due-diligence/due-diligence-enhance.svg";
import dueDiligenceEnhanceImg2 from "../../images/invesment-solution/due-diligence/due-diligence-enhance2.svg";
import ipadImage from "../../images/headers/due-diligence.png";
import screenPdf from "../../images/invesment-solution/due-diligence/screenPdf-due-diligence.png";
import { theme } from "../../themeV2";

const metadata = {
  title: "Due Diligence Portal",
  description: "Build your own report.",
};

const BUILD_ITEMS = [
  {
    title: <Trans>Customize</Trans>,
    description: (
      <Trans>
        Build your own report, share within your own entity or with the regulator
      </Trans>
    ),
  },
  {
    title: <Trans>Distribute</Trans>,
    description: (
      <Trans>
        Build your own report, share within your own entity or with the regulator
      </Trans>
    ),
  },
];

const ITEMS = [
    {
        icon: yellowIcon,
        color: theme.palette.colors.yellow.main,
        title: (
          <Trans>
            Allfunds Due Diligence Database
          </Trans>
        ),
        description: (
          <Trans>
            Harness Allfunds’ fund groups network in an exclusive, centralised  Due Diligence database
          </Trans>
        ),
      },
  {
    icon: OrangeIcon,
    color: theme.palette.colors.orange.dark,
    title: <Trans>Assessment workflow wizard</Trans>,
    description: (
      <Trans>
        Assess all responses from fund groups to support your fund and fund house catalog decisionst.
      </Trans>
    ),
  },
  {
    icon: greenIcon,
    color: theme.palette.colors.green.main,
    title: <Trans>Embedded audit trails & alerts</Trans>,
    description: (
      <Trans>
        One portal to monitor and trace all changes from the fund house, your assessments, and approvals
      </Trans>
    ),
  },
  {
    icon: blueIcon,
    color: theme.palette.colors.blue.light,
    title: <Trans>Compliant reporting</Trans>,
    description: (
      <Trans>
        Real-time data connectivity with multiple data sources
      </Trans>
    ),
  },
];

const requestInfoSubject = "Invesment Solution - Due Diligence Portal";

function DueDiligenceHome() {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLessThan1440 = useMediaQuery(theme.breakpoints.down("1440"));
  const isGreaterThan900 = useMediaQuery(theme.breakpoints.up("1200"));
  const isLaptopView = isLessThan1440 && isGreaterThan900;

  const handleOnclickInfo = () => {
    setShowModal(true);
  };

  const renderRequestButton = () => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => setShowModal(true)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const renderItem = ({ title, description, color, icon }) => {
    return (
      <CardColor color={color} icon={icon} title={title} description={description} />
    );
  };

  const buildLeftImageSection = () => {
    return (
      <Grid item xs={12} lg={6} sx={{ position: "relative" }} width="100%" paddingLeft={{ xs: "8%", sm: 0 }}>
        <AnimatedDiv direction="left">
          <StyledBox isLaptopView={isLaptopView}>
            <Grid container spacing={2} pt={2} alignItems={{ sm: "center", lg: "flex-start" }}>
              <Grid item xs={12} sm={6} lg={12}>
                <Box
                  display="flex"
                  justifyContent={{ xs: "center", sm: "center", lg: "flex-start" }}>
                  <CustomEnhanceImage theme={theme} alt="enhance" src={dueDiligenceEnhanceImg} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={12} sx={{ paddingTop: "0 !important" }}>
                <Box
                  display="flex"
                  mt={{ xs: 0, sm: 2.2, lg: 0}}
                  justifyContent={{ sm: "center", lg: "flex-end" }}
                  sx={{ transform: isLaptopView ? "translate(15px, 0px)" : "translate(0px, 0px)" }}>
                  <CustomEnhanceImage2 theme={theme} alt="enhance" src={dueDiligenceEnhanceImg2} />
                </Box>
              </Grid>
            </Grid>
          </StyledBox>
        </AnimatedDiv>
      </Grid>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Seo title={metadata.title} description={metadata.description} />
      <CssBaseline />
      <HeroLading
        title={
          <Trans>Fast-track your Due Diligence workflow, from data to reporting.</Trans>
        }
        description={
          <Trans>
            Access accurate, up-to-date Due Diligence material from Allfunds’ standard database. Run assessments and generate regulation compliant reports that support your fund catalog.
          </Trans>
        }
        handleOnclickInfo={() => handleOnclickInfo()}
        multimedia={ipadImage}
        kind="Due Diligence Portal"
        isImageToBorder={true}
        kindColor={theme.palette.colors.yellow.main}
      />
      <Container pt={{ xs: 0, sm: 2 }} pb={{ xs: 5, sm: 8 }} sx={{ overflow: "hidden" }}>
        <Grid container spacing={4} alignItems="start" sx={{ position: "relative" }} flexDirection={{ xs: "column-reverse", lg: "row" }}>
          {!isMobile && buildLeftImageSection()}
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Stack spacing={1.5} pb={6}>
              <Typography variant="text" fontWeight={700} fontSize={20} color={theme.palette.colors.yellow.main}>
                <Trans>
                  Due Diligence Portal
                </Trans>
              </Typography>
              <Typography variant="h3">
                <Trans>Enhances the whole Due Diligence workflow by moving all interactions to the digital space</Trans>
              </Typography>
              <Typography>
                <Trans>
                  Allfunds provides a single digital portal that offers a digital environment to retrieve, request and monitor Due Diligence information, with all the information in one place, audit trails for traceability, and regulatory reporting to create their final catalog.
                </Trans>
              </Typography>
              {renderRequestButton()}
            </Stack>
            {isMobile && buildLeftImageSection()}
            <Box
              display="grid"
              gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr" }}
              gridTemplateRows={{ xs: "auto", sm: "auto auto" }}
              gap={{ xs: 0, sm: 2, lg: 0 }}
              alignItems="center"
              justifyContent={{ xs: "center", sm: "center", lg: "flex-start" }}
              width="100%"
              >
              <Box
                gridColumn={{ xs: "1" }}
                gridRow={{ xs: "1", sm: "1 / 3" }}
                sx={{ marginRight: { xs: 0, lg: "12px !important" } }}
                >
                <AnimatedDiv direction="down">
                  {ITEMS.slice(0, 2).map((item) => (
                  renderItem({ ...item, isHovered: hoveredCard, setIsHovered: setHoveredCard })
                ))}
                </AnimatedDiv>
              </Box>

              <Box
                gridColumn={{ xs: "1", sm: "2" }}
                gridRow={{ xs: "2", sm: "1 / 3" }}
                pt={{ xs: 0, sm: 0, lg: 10 }}
                sx={{ marginLeft: { xs: 0, lg: "12px !important" } }}
              >
                <AnimatedDiv direction="up">
                  {ITEMS.slice(2, 4).map((item) => (
                  renderItem({ ...item, isHovered: hoveredCard, setIsHovered: setHoveredCard })
                ))}
                </AnimatedDiv>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 8 }} sx={{ backgroundColor: theme.palette.colors.grey.light }} display="flex" justifyContent="center">
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          gap={1}
          textAlign="center"
          width={{ xs: "80%", lg: "50%"}}
          >
          <Typography variant="h3" textAlign="center">
            <Trans>Cloud-based collaborative workflow</Trans>
          </Typography>
          <Typography>
            <Trans>
              Allfunds InvestFlow is a cloud-based SaaS solution that keeps everything strictly up-to-date and allows your organisation to collaborate from a central Due Diligence hub.
            </Trans>
          </Typography>
          {renderRequestButton()}
        </Box>
      </Box>
      <Container pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 8 }}>
        <Grid container spacing={15} alignItems="center">
          <Grid item xs={12} md={6}>
            <Stack spacing={1.5}>
              <Typography variant="text" fontWeight={700} fontSize={20} color={theme.palette.colors.yellow.main}>
                Due Diligence Portal
              </Typography>
              <Typography variant="h3" lineHeight={1}>
                <Trans>
                  Personalised PDF reports that comply with regulations
                </Trans>
              </Typography>
              <Typography>
                <Trans>
                  Support your fund and fund house catalog with time-stamped reports that collect a full record of your assessment workflow.
                </Trans>
              </Typography>

            </Stack>
            <Grid container spacing={{ xs: 1, sm: 4, md: 6, lg: 8 }} pb={4}>
              {BUILD_ITEMS.map(({ title, description }) => (
                <Grid item xs={12} sm={6}>
                  <Stack pt={2} spacing={1}>
                    <Typography fontWeight={700}>{title}</Typography>
                    <Typography>
                      {description}
                    </Typography>
                  </Stack>
                </Grid>
                ))}
            </Grid>
            {renderRequestButton()}
          </Grid>
          <Grid item xs={12} md={6} style={isMobile ? {paddingTop: "40px"} : {}}>
            <AnimatedDiv direction="right" viewportAmount={0.8}>
              <img width="100%" alt="screen-pdf" src={screenPdf} />
            </AnimatedDiv>
          </Grid>
        </Grid>
      </Container>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject={requestInfoSubject}
          buttonColor="secondary"
        />
      )}
    </ThemeProvider>
  );
}

export default DueDiligenceHome;

const StyledBox = styled(Box)(({ isLaptopView }) => ({
  position: isLaptopView ? "absolute" : "relative",
  right: isLaptopView ? "14%" : "0",
  width: "100%",
}));

const CustomEnhanceImage = styled.img(({ theme }) => ({
  width: "85%",
  height: "auto",
  [theme.breakpoints.down("1200")]: {
    width: "87%",
  },
  [theme.breakpoints.down("800")]: {
    width: "100%",
  },
  [theme.breakpoints.down("600")]: {
    width: "100%",
  },
}));

const CustomEnhanceImage2 = styled.img(({ theme }) => ({
  width: "85%",
  height: "auto",
  [theme.breakpoints.down("1200")]: {
    width: "100%",
  },
  [theme.breakpoints.down("800")]: {
    width: "114%",
  },
  [theme.breakpoints.down("600")]: {
    width: "100%",
  },
}));
